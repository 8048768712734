<template>
	<div>
		<div v-if="!trip.loading && !trip.error && trip.tripItems[trip_item_id]">

			<div v-if="trip.tripItems[trip_item_id].type == 'flight'">
				<FlightHeader
					class="header"

					:tripID="trip_id"
					:flightName="trip.tripItems[trip_item_id].name"
					:flightStartDate="trip.tripItems[trip_item_id].data.outbound.departure"
					:flightEndDate="trip.tripItems[trip_item_id].data.return ? trip.tripItems[trip_item_id].data.return.arrival : trip.tripItems[trip_item_id].data.outbound.arrival"
				/>
			</div>

			<div v-else-if="trip.tripItems[trip_item_id].type == 'tour'">
				<TourHeader
					class="header"

					:tripID="trip_id"
					:images="trip.tripItems[trip_item_id].data.rawTourData.images"
					:tourName="trip.tripItems[trip_item_id].data.rawTourData.name"
					:tourStartLocation="trip.tripItems[trip_item_id].data.rawTourData.itinerary ? trip.tripItems[trip_item_id].data.rawTourData.itinerary.data.start_location.name : ''"
					:tourEndLocation="trip.tripItems[trip_item_id].data.rawTourData.itinerary && trip.tripItems[trip_item_id].data.rawTourData.itinerary.data.end_location && trip.tripItems[trip_item_id].data.rawTourData.itinerary.data.end_location.name != trip.tripItems[trip_item_id].data.rawTourData.itinerary.data.start_location.name ? trip.tripItems[trip_item_id].data.rawTourData.itinerary.data.end_location.name : false"
					:operator="trip.tripItems[trip_item_id].data.rawTourData.booking_companies.join(', ')"

					:backLink="flow == 'add' ? {name: 'Trip', params: { trip_id: this.trip_id } } : null"
				/>
			</div>
		</div>

		<div class="outer-wrapper mobile-only">
			<Loader :loading="trip.loading" :error="trip.error" @refresh="refresh"/>

			<div class="inner-wrapper" v-if="!trip.loading && !trip.error && travellers"> <!-- Inner wrapper handles global padding -->

				<h2 class="heading">Passenger list</h2>

				<div class="friends">
					<!-- {{ travellers }} -->
					<div class="traveller-wrapper">
						<AddTraveller 
							text="Add new passenger"

							:tripID="trip_id"
							:tripItemID="trip_item_id"
						/> 
					</div>

					<div class="traveller-wrapper"
						v-for="traveller in travellers"
						v-bind:key="traveller.traveller_id"
					>	
						<TravellerSelector 
							:travellerID="traveller.traveller_id"
							:firstName="traveller.data.firstName"
							:lastName="traveller.data.lastName"
							:dob="traveller.data.dob"
							:passportNumber="traveller.data.passportNumber"
							:passportExpiry="traveller.data.passportExpiry"
							:type="traveller.type ? traveller.type : ''"
							:nationality="traveller.data.countryOfIssue"

							:validationErrors="traveller.validationErrors"

							:relatedTravellerID="traveller.related_passenger_id"
							:relatedTravellerOptions="adultTravellers"

							:tripID="trip_id"
							:tripItemID="trip_item_id"

							:toggled="traveller.toggled"
							:loading="travellersLoading"

							@update="updateData()" 
						/>
					</div>
				</div>

				<h2 class="heading" v-if="friends.length > 0">All friends</h2>

				<div class="friends" v-if="friends.length > 0">
					<div class="traveller-wrapper"
						v-for="traveller in friends"
						v-bind:key="traveller.traveller_id"
					>
						<TravellerSelector 
							:travellerID="traveller.traveller_id"
							:firstName="traveller.data.firstName"
							:lastName="traveller.data.lastName"
							:dob="traveller.data.dob"
							:passportNumber="traveller.data.passportNumber"
							:passportExpiry="traveller.data.passportExpiry"
							:type="traveller.type ? traveller.type : ''"
							:nationality="traveller.data.countryOfIssue"

							:validationErrors="traveller.validationErrors"

							:relatedTravellerID="traveller.related_passenger_id ? traveller.related_passenger_id : null"
							:relatedTravellerOptions="adultTravellers"

							:tripID="trip_id"
							:tripItemID="trip_item_id"
						
							:toggled="traveller.toggled"
							:loading="travellersLoading"

							@update="updateData()" 
						/>
					</div>
				</div>
				
				<div class="validation-wrapper" v-if="!trip.loading && !trip.error && trip.tripItems[trip_item_id]">	

					<div class="validation" :class="{ 'loading': travellersLoading }">

						<div class="pricing-table" v-if="trip.tripItems[trip_item_id].travellerTypePricing">
							<h2 class="heading" >Pricing</h2>
							<div v-for="(value, key) in trip.tripItems[trip_item_id].travellerTypePricing" :key="key" class="type">
								<img v-if="key == 'Adult' || key == 'Child' || key == 'Infant'" :src="getImage('travellers/' + key.toLowerCase() + '-icon')" class="pax-icon" :alt="type + 'Icon'" />
								<img v-else src="@/assets/travellers/adult-icon.png" class="pax-icon" :alt="type + 'Icon'" />
								{{ key }}<span v-if="value.minAge && value.maxAge"> (ages {{ value.minAge }} - {{ value.maxAge }})</span>: £{{ value.price }} x {{ trip.tripItems[trip_item_id].totalTravellers[key] }}
								<span class="price">£{{ value.price * trip.tripItems[trip_item_id].totalTravellers[key] }}</span>
							</div>
							<!-- Don't show the total if the trip is not valid, as the total is based on expected number of pax, not actual number. Will be wrong total if too many/few pax are on the trip (therefore invalid) -->
							<div class="total" v-if="tripItemTravellersValidity(trip_item_id).valid">
								Total Amount
								<span class="total-value">£{{ trip.tripItems[trip_item_id].price }}</span>
							</div>
						</div>

						<div class="selected">You selected {{ trip.tripItems[trip_item_id].travellers.length }} passengers</div>

						<!-- Below section only shown on flights at the moment (not tours as there is no required travellers) -->
						<div class="required" v-if="tripItemTravellersValidity(trip_item_id).valid && trip.tripItems[trip_item_id].totalRequiredTravellers">
							<img class="alert-icon" src="@/assets/travellers/yellow-alert-icon.png" alt="Alert Icon" />
							<span class="text">
								Required:
								<span v-if="trip.tripItems[trip_item_id].totalRequiredTravellers.Adult > 0">{{ trip.tripItems[trip_item_id].totalRequiredTravellers.Adult }} Adult</span>
								<span v-if="trip.tripItems[trip_item_id].totalRequiredTravellers.Child > 0 || trip.tripItems[trip_item_id].totalRequiredTravellers.Infant > 0">, </span>
								
								<span v-if="trip.tripItems[trip_item_id].totalRequiredTravellers.Child > 0">{{ trip.tripItems[trip_item_id].totalRequiredTravellers.Child }} Children</span>
								<span v-if="trip.tripItems[trip_item_id].totalRequiredTravellers.Infant > 0">, </span>
								
								<span v-if="trip.tripItems[trip_item_id].totalRequiredTravellers.Infant > 0">{{ trip.tripItems[trip_item_id].totalRequiredTravellers.Infant }} Infant</span>
								</span>
						</div>

						<div class="total">
							<span v-for="type in Object.keys(trip.tripItems[trip_item_id].totalTravellers)" :key="type">
								<span class="pax" :class="{ type, 'incomplete': trip.tripItems[trip_item_id].totalTravellers[type] - trip.tripItems[trip_item_id].totalRequiredTravellers[type] < 0}" v-if="(trip.tripItems[trip_item_id].totalRequiredTravellers && trip.tripItems[trip_item_id].totalRequiredTravellers[type] && trip.tripItems[trip_item_id].totalRequiredTravellers[type] != 0) || trip.tripItems[trip_item_id].totalTravellers[type] != 0">
									
									<img v-if="type == 'Invalid'" src="@/assets/travellers/big-alert.png" class="pax-icon" :alt="type + 'Icon'" />
									<img v-else-if="key == 'Adult' || key == 'Child' || key == 'Infant'" :src="getImage('travellers/' + key.toLowerCase() + '-icon')" class="pax-icon" :alt="type + 'Icon'" />
									<img v-else src="@/assets/travellers/adult-icon.png" class="pax-icon" :alt="type + 'Icon'" />
									
									{{ trip.tripItems[trip_item_id].totalTravellers[type] }} {{ type }}

									<span class="counter" v-if="trip.tripItems[trip_item_id].totalRequiredTravellers"> <!-- If required travellers, display ticks based on required traveller validation -->
										<span v-for="i in trip.tripItems[trip_item_id].totalRequiredTravellers[type]" :key="i">
											<img v-if="i <= trip.tripItems[trip_item_id].totalTravellers[type]" src="@/assets/travellers/green-tick.png" class="tick" alt="Green Tick"/>
											<img v-else src="@/assets/travellers/grey-tick.png" class="tick" alt="Grey Tick"/>
										</span>
										<span v-if="trip.tripItems[trip_item_id].totalTravellers[type] - trip.tripItems[trip_item_id].totalRequiredTravellers[type] > 0">
											<span v-for="i in (trip.tripItems[trip_item_id].totalTravellers[type] - trip.tripItems[trip_item_id].totalRequiredTravellers[type])" :key="i">
												<img src="@/assets/travellers/yellow-tick.png" class="tick" alt="Yellow Tick"/>
											</span>
										</span>
										<!-- If type is 'invalid' display red -->
										<span v-for="i in trip.tripItems[trip_item_id].totalTravellers[type]" :key="i">
											<img v-if="type=='Invalid'" src="@/assets/travellers/red-tick.png" class="tick" alt="Red Cross"/>
										</span>

									</span>

									<span class="counter" v-else> <!-- If no required travellers, green ticks for them all, except for the "Invalid" type -->
										<span v-for="i in trip.tripItems[trip_item_id].totalTravellers[type]" :key="i">
											<img v-if="type=='Invalid'" src="@/assets/travellers/red-tick.png" class="tick" alt="Red Cross"/>
											<img v-else src="@/assets/travellers/green-tick.png" class="tick" alt="Green Tick"/>
										</span>
									</span>

								</span>
							</span>
						</div>
						<div v-if="!tripItemTravellersValidity(trip_item_id).valid" class="message">
							<img src="@/assets/travellers/big-alert.png" alt="Alert Icon" />
							{{ tripItemTravellersValidity(trip_item_id).error }}
						</div>

					</div>
					<Loader class="loader" :loading="travellersLoading"/>
				</div>


				<!-- TODO: Change buttons for 'add' version -->
				<div class="actions inner-wrapper" v-if="!trip.loading && !trip.error && trip.tripItems[trip_item_id]">
					<button @click="book()" class="button" :class="{ 'disabled': travellersLoading || !tripItemTravellersValidity(trip_item_id).valid}">
						Book now
					</button>

					<button v-if="flow == 'trip' || flow == null" @click="conductBackAction()" class="button" :class="{ 'disabled': travellersLoading }">
						View Trip
					</button>
					<button v-if="flow == 'add'" @click="viewTrip()" class="button" :class="{ 'disabled': travellersLoading }">
						View Trip
					</button>

					<button v-if="flow == 'trip-item' || flow == 'checkout'" @click="conductBackAction()" class="button" :class="{ 'disabled': travellersLoading }">
						View {{ trip.tripItems[trip_item_id].type }}
					</button>

					<div v-if="!tripItemTravellersValidity(trip_item_id).valid && trip.tripItems[trip_item_id].totalRequiredTravellers">
						<div class="or"><strong>or</strong></div>
						<div class="new-flight">If you need to change traveller quantity, select a new flight offer</div>

						<button class="button" @click="replaceFlight()">Search</button>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.outer-wrapper {
		padding-bottom: 60px;	
	}
	a {
		text-decoration: none;
	}
	.heading {
		color: #118AB2;
	}
	.button {
		margin-top: 18px;
	}
	/* Header */
	.header {
		margin-bottom: 20px;
	}
	/* pax */
	.friends .traveller-wrapper {
		text-align: center;
		margin-bottom: 15px;
	}
	.friends .traveller-wrapper .traveller {
		display: block;
	}
	img.icon {
		position: absolute;
		top: 21px;
		right: 40px;
	}
	/* end pax */


	/* Validation */
	.validation-wrapper {
		position: relative;
	}
	.validation-wrapper .loader {
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}
	
	.validation {
		text-align: center;
		margin-top: 56px;
	}
	.validation.loading {
		opacity: 0.3;
	}
	.validation .selected {
		font-weight: 700;
		font-size: 20px;
		margin-bottom: 30px;
	}
	.validation .required,
	.validation .pricing {
		margin-bottom: 30px;
	}
	.validation .required .alert-icon,
	.validation .pricing .alert-icon {
		width: 14px;
		vertical-align: middle;
	}
	.validation .required .text,
	.validation .pricing .text {
		width: 14px;
		vertical-align: middle;
	}
	.validation .pax {
		font-weight: 400;
		margin-right: 10px;
		display: inline-block;
		vertical-align: top;
		min-width: 100px;
	}
	.validation .pax.incomplete {
		opacity: 0.5;
	}
	.validation .pax .pax-icon {
		width: 29px;
		height: 29px;
		vertical-align: middle;
		margin: 0 auto;
		display: block;
		margin-bottom: 10px;
	}
	.validation .pax .counter {
		display: block;
		max-width: 100px;
		margin: 0 auto;
		margin-top: 10px;
	}
	.validation .pax .counter .tick {
		width: 14px;
		margin: 0 2px;
	}
	.validation .message {
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 15px;
		font-size: 12px;
		position: relative;
		padding-left: 46px;
		width: 253px;
	}
	.validation .message img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 46px;
	}
	/* End Validation */
	
	.total-price{
		margin-top: 10px;
	}

	.actions {
		text-align: center;
	}
	.actions .or {
		margin-top: 20px;
	}
	.actions .button {
		text-transform: capitalize;
		margin: 18px 5px 0 5px;
	}
	/* Booking status */
	.booking_status {
		border: 1px solid;
		display: inline-block;
		padding: 2px 5px;
		border-radius: 8px;

		float: right;
		font-weight: bold;

		background-color: white;
	}
	.booking_status.not_booked {
		border-color: red;
		color: red;
	}
	.booking_status.booked {
		border-color: green;
		color: green;
	}

</style>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import Menu from '@/components/Menu.vue'
	import Loader from '@/components/Loader.vue';
	import TravellerSelector from '@/components/travellers/TravellerSelector.vue';
	import AddTraveller from '@/components/travellers/AddTraveller.vue';
	import TourHeader from "@/components/tours/TourHeader.vue";
	import FlightHeader from "@/components/flights/FlightHeader.vue";

	export default {
		name: 'manageTripItemTravellers',
		props: {
			flow: {
				// Options are 'checkout', 'trip', 'trip-item'
				// Used to determine buttons to show and where to redirect, based on where the user came from or the flow of the app.
				type: String,
				required: false,
				default: null,
			}
		},
		data() {
			return {
				carbon: {
					img: "flights-details/carbon-forest-bg",	
				},
				score: {
					img: "flights-details/circle-score",
				},
				plane_img: {
					img: "trip/plane-image",
				},

				trip_id: this.$route.params.trip_id ? parseInt(this.$route.params.trip_id) : null,
				trip_item_id: this.$route.params.trip_item_id ? parseInt(this.$route.params.trip_item_id) : null,

				friends: [],
				travellers: [],
				adultTravellers: [], // For passing through to TravellerSelector for related adults selecting (infants)
			}
		},
		components: {
			Menu,
			Loader,
			AddTraveller,
			TravellerSelector,
			TourHeader,
			FlightHeader,
		},
		computed: {
			...mapState({
				trip: state => state.trip,
			}),
			fare() {
				return this.trip.tripItems[this.trip_item_id].fare;
			},
			data() {
				return this.trip.tripItems[this.trip_item_id].data.flightDetails;
			},
			status() {
				return this.trip.tripItems[this.trip_item_id].bookingStatus;
			},
			...mapGetters({
				tripItemBookingValidity: "trip/tripItemBookingValidity",
				tripItemTravellersValidity: "trip/tripItemTravellersValidity",
				travellersLoading: "trip/travellersLoading",
			})
		},
		methods: {
			...mapActions({
				prefillSearch: "flightsSearch/prefillSearchValues",
				setQty: "flightsSearch/setQty",
				initTrip: "trip/initTrip",
				alertError: "alert/error",
			}),
			formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
			getImage: (img) => helpers.getImage(img),
			formatDate: (date) => helpers.formatDate(date),
			// Fetch all state data for travellers and store in local arrays for use in this component.
			// Toggled attribute is added based on whether the traveller is already selected for this trip item.
			calcTravellers() {
				this.travellers = [];
				this.friends = [];
					
				let existingTravellerIds = [];
				if(this.trip.tripItems[this.trip_item_id].travellers) {
					this.trip.tripItems[this.trip_item_id].travellers.forEach((traveller) => { 
						traveller.toggled = true;
						this.travellers.push(traveller);
						existingTravellerIds.push(parseInt(traveller.traveller_id)); // Build array of Pax IDs that ARE on the trip item
						if(traveller.type && traveller.type == "Adult") {
							this.adultTravellers.push(traveller);
						}
					});
				}

				if(this.trip.tripTravellers) {
					this.trip.tripTravellers.forEach((traveller) => {
						if(!existingTravellerIds.includes(parseInt(traveller.traveller_id))) { // Add traveller option only if they are not on the trip item already
							traveller.toggled = false;
							this.travellers.push(traveller);
							existingTravellerIds.push(parseInt(traveller.traveller_id)); // Build array of Pax IDs that are already included (and so shouldn't be included in friends section)
						}
					});
				}

				if(this.trip.userTravellers) {
					this.trip.userTravellers.forEach((traveller) => {
						traveller.toggled = false;
						if(!existingTravellerIds.includes(parseInt(traveller.traveller_id))) { // Add traveller option only if they are not on the trip item already
							this.friends.push(traveller);
						}
					});
				}
			},
			// Loop through all local traveller arrays, and ensure they have up to date data.
			// This function is very similar to calcTravellers() but it ensures that the order of travellers does not change - to avoid travellers changing position visually. Does not set toggled. Mostly just needed to ensure traveller validation is up to date.
			reCalcTravellers() {
				// TODO: Concat the various arrays and then just do one .find() call?
				this.travellers.forEach((traveller, index, array) => { 
					// Find the traveller to get the updated data.
					let find = this.trip.tripItems[this.trip_item_id].travellers.find(stateTraveller => stateTraveller.traveller_id == traveller.traveller_id);
					if(!find) { // If not found, it's maybe a trip traveller
						find = this.trip.tripTravellers.find(stateTraveller => stateTraveller.traveller_id == traveller.traveller_id);
					}
					if(!find) { // If not, it's a friend
						find = this.trip.userTravellers.find(stateTraveller => stateTraveller.traveller_id == traveller.traveller_id);
					}
					array[index] = find; // Update the traveller with the new data.
				});
				
				this.friends.forEach((traveller, index, array) => { 
					// Find the traveller to get the updated data. First check travellers to see if it has moved (to get the new validation data)
					let find = this.trip.tripItems[this.trip_item_id].travellers.find(stateTraveller => stateTraveller.traveller_id == traveller.traveller_id);
					if(!find) { // If not in travellers, maybe is still in friends.
						find = this.trip.userTravellers.find(stateTraveller => stateTraveller.traveller_id == traveller.traveller_id);
					}
					array[index] = find; // Update the traveller with the new data.
				});
			},
			updateData() {
				this.reCalcTravellers(); // re-caclulate travellers as the state has changed. 

				// Update list of adults for selection on infants
				this.adultTravellers = [];
				this.trip.tripItems[this.trip_item_id].travellers.forEach((traveller) => { 
					if(traveller.type && traveller.type == "Adult") {
						this.adultTravellers.push(traveller);
					}
				});
			},
			book() {
				if(this.status.code == 'not_booked') {
					if(this.tripItemTravellersValidity(this.trip_item_id).valid) {
						if(!this.tripItemBookingValidity(this.trip_item_id).valid) {
							// Booking validity not valid (e.g. fare selection, expired etc) - redirect back to trip item page
							this.viewTripItem();
						} else {
							// Else continue with booking
							router.push({
								name: "Review",
								params: {
									trip_id: this.trip_id,
									trip_item_id: this.trip_item_id
								}
							})
						}
						
					} else { // Missing pax details
						this.alertError(this.tripItemTravellersValidity(this.trip_item_id).error);
					}
				}
			},
			back() {
				router.go(-1);
			},
			viewTrip() {
				router.push({
					name: 'Trip', 
					params: { 
						trip_id: this.trip_id, 
					} 
				});
			},
			viewTripItem() {
				if(this.trip.tripItems[this.trip_item_id].type == "flight") {
					router.push({
						name: 'Saved Flights Details', 
						params: { 
							trip_id: this.trip_id, 
							trip_item_id: this.trip_item_id
						} 
					});
				} else if(this.trip.tripItems[this.trip_item_id].type == "tour") {
					router.push({
						name: 'Saved Tour Details', 
						params: { 
							trip_id: this.trip_id, 
							trip_item_id: this.trip_item_id
						} 
					});
				}
			},
			conductBackAction() {
				if(this.flow == 'checkout' || this.flow == 'trip-item') {
					this.viewTripItem();
				} else if(this.flow == 'trip') {
					this.viewTrip();
				} else {
					this.back();
				}
			},
			replaceFlight() {
				// Prefill the flightsSearch state using the existing flight data
				this.prefillSearch({ tripItemID: this.trip_item_id });
				// Set pax qty based on the actual added passengers
				this.setQty({ adults: this.trip.tripItems[this.trip_item_id].totalTravellers.Adult, children: this.trip.tripItems[this.trip_item_id].totalTravellers.Child, infants: this.trip.tripItems[this.trip_item_id].totalTravellers.Infant });

				// Navigate to the search process with query param for the trip item to replace
				router.push({
					name: 'Flights Search',
					query: {
						trip_id: this.trip_id,
						trip_item_id: this.trip_item_id,
					}
				})
			},
			async refresh() {
				await this.initTrip({ tripID: this.trip_id, tripItemID: this.trip_item_id });

				// If the flow is "trip" as default
				let backRoute = {
					name: 'Trip',
					params: {
						trip_id: this.trip_id,
					}
				};
				// But usually it's the other flows (add, checkout, trip-item)
				if(this.flow != 'trip') {

					if(this.trip.tripItems[this.trip_item_id].type == "flight") {
						backRoute = ({
							name: 'Saved Flights Details', 
							params: { 
								trip_id: this.trip_id, 
								trip_item_id: this.trip_item_id
							} 
						});
					} else if(this.trip.tripItems[this.trip_item_id].type == "tour") {
						backRoute = ({
							name: 'Saved Tour Details', 
							params: { 
								trip_id: this.trip_id, 
								trip_item_id: this.trip_item_id
							} 
						});
					}
				}

				// Update the back button route now that we know what type of trip item / flow it is
				this.$emit('update:topLayoutSettings', {
					left: {
						type: 'back',
						color: 'white',
						route: backRoute,
					},
					right: {
						type: 'none',
					},
					overlayContent: true,
				});

				this.calcTravellers();
			}
		},
		async created() {
			this.$emit('update:topLayoutSettings', {
				hide: true,
			});
			
			// BUG: For some reason, after adding a new traveller (and calcTravellers is re-called) - then toggling a traveller (reCalcTravellers is called) - the new traveller is 'undefined' in the this.travellers array. I can't figure out what is happening . It is fine after the calcTravellers call, but when reCalc is called, it is undefined. No idea.
			// For now, the full trip is reloading in trip.js function to ensure all data is correct

			await this.refresh();
		}
	}
</script>

