<template>
	<div class="container" :style="{'background-image': 'url(' + getImage('trip/plane-image') + ')'}">
		<div class="gradient"></div>
		<div class="inner outer-wrapper mobile-only">
			<div class="type">Flight</div>
			<h1 class="heading4 name">{{ flightName }}</h1>
			<div class="location">
				<span class="start">{{ formatDateWithoutDay(flightStartDate) }}</span>
				<span class="end" v-if="flightEndDate"> - {{ formatDateWithoutDay(flightEndDate) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import helpers from '@/helpers/helpers.js';
	export default {
		name: 'FlightsHeader',
		props: {
			tripID: {
				default: false,
			},
			flightName: {
				default: "",
			},
			flightStartDate: {
				default: "",
			},
			flightEndDate: {
				default: "",
			},
			// backLink: {
			// 	type: Object,
			// 	default: false,
			// }
		},
		data() {
			return {
			}
		},
		computed: {
			// Backlink has been deprecated in favour of the global layouts
			// getBackLink() {
			// 	if(this.backLink) {
			// 		return this.backLink;
			// 	} else if(this.tripID) {
			// 		return { name: 'Trip', params: { trip_id: this.tripID } }
			// 	} else {
			// 		return { name: 'Flights Results' }
			// 	}
			// }
		},
		methods: {
			getImage: (img) => helpers.getImage(img),
			formatDateWithoutDay: (date) => helpers.formatDateWithoutDay(date),
		},
	}
</script>

<style scoped>
	.container {
		color: white;
		height: 250px;
		position: relative;
		background-size: cover;
		background-position: center;
	}
	.container .gradient {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00272A 100%);
	}
	.container .inner {
		padding: 19px;
		position: absolute;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		left: 50%;
		transform: translateX(-50%);
	}
	.container .inner .type {
		display: inline-block;
		padding: 0 5px;
		margin-bottom: 5px;
		height: 14px;
		background: #7F3D9E;
		border-radius: 9px;

		font-weight: 700;
		font-size: 10px;
		line-height: 14px;
		color: white;
		text-align: center;
		text-transform: uppercase;
	}
	.container .inner .name {
		margin-bottom: 5px;
		line-height: 28px;
	}
	.container .inner .location {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
	}
	.container .inner .location .location-icon {
		width: 8px;
		margin-right: 5px;
	}
</style>