<template>
	<div class="container" :style="{'background-image': 'url(' + imageHref + ')'}">
		<div class="gradient"></div>
		<div class="inner outer-wrapper mobile-only">
			<div class="type">Tour</div>
			<h1 class="heading4 name">{{ tourName }}</h1>
			<div class="location">
				<img class="location-icon" src="@/assets/tours/location-icon-white.png">
				<span class="start">{{ tourStartLocation }}</span>
				<span class="end" v-if="tourEndLocation"> - {{ tourEndLocation }}</span>
			</div>
			
			<div class="operator" v-if="operator && operator != 'false'">
				Operated by <strong>{{ operator }}</strong>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TourHeader',
		props: {
			tripID: {
				default: false,
			},
			images: {
				default: "",
			},
			tourName: {
				default: "",
			},
			tourStartLocation: {
				default: "",
			},
			tourEndLocation: {
				default: "",
			},
			operator: {
				default: "",
			},
			backLink: {
				type: Object,
				default: null,
			}
		},
		data() {
			return {
				imagesPriority: [
					"LARGE_SQUARE",  // Lowest priority
					"BANNER", 
					"BANNER_HIRES", // This is actually highest quality, but it's excessive for our purposes. Still better than regular 'BANNER' though.
					"BANNER_DESKTOP" // Highest priority
				],
				imageHref: "",
			}
		},
		computed: {
		},
		methods: {
			getImage: (img) => helpers.getImage(img),
			formatTime: (date) => helpers.formatTime(date),
			formatDate: (date) => helpers.formatDate(date),
			formatTimeRange: (timeStamp) => helpers.formatTimeRange(timeStamp),
		},
		created() {
			// Order images based on priority
			if(this.images && this.images.length > 0) {
				let orderedImages = this.images.sort((a, b) => {
					// Order based on imagesPriority
					let aIndex = this.imagesPriority.indexOf(a.type);
					let bIndex = this.imagesPriority.indexOf(b.type);
					if(aIndex > bIndex) {
						return -1;
					}
					if(aIndex < bIndex) {
						return 1;
					}
					return -1;
				});
				// Set the main image href to the highest priority image
				this.imageHref = orderedImages[0].image_href;
			}
			
		}
	}
</script>

<style scoped>
	.container {
		color: white;
		height: 250px;
		position: relative;
		background-size: cover;
		background-position: center;
	}
	.container .gradient {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00272A 100%);
	}
	.container .inner {
		padding: 19px;
		position: absolute;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		left: 50%;
		transform: translateX(-50%);
	}
	.container .inner .type {
		display: inline-block;
		padding: 0 5px;
		margin-bottom: 5px;
		height: 14px;
		background: #4CBCC4;
		border-radius: 9px;

		font-weight: 700;
		font-size: 10px;
		line-height: 14px;
		color: white;
		text-align: center;
		text-transform: uppercase;
	}
	.container .inner .name {
		margin-bottom: 5px;
		line-height: 28px;
	}
	.container .inner .location {
		font-weight: 400;
		font-size: 12px;
		line-height: 12px;
		vertical-align: middle;
		float: left;
	}
	.container .inner .location .location-icon {
		width: 8px;
		margin-right: 5px;
		vertical-align: middle;
	}
	.container .inner .operator {
		display: inline-block;
		font-weight: 400;
		font-size: 11px;
		float: right;
	}
</style>